<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="10" sm="8" md="6" lg="4">
        <v-card>
          <v-toolbar :color="actionColor" dark>
            <v-toolbar-title>{{ $helper.tRoute($route.name) }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon @click="$router.replace({ name: 'home' })">mdi-close</v-icon>
          </v-toolbar>
          <v-card-text>
            <div class="text--primary">{{ $t("Auth.forgotPasswordText") }}</div>
            <v-divider></v-divider>
            <v-form id="form" ref="form">
              <v-text-field
                v-model="form.email"
                id="email"
                :label="$t('Cols.users.email')"
                maxlength="35"
                ref="email"
                :rules="[rules.required, rules.email]"
                validate-on-blur
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              block
              @click="forgotPassword"
              :color="actionColor"
              dark
              id="emailPasswordResetLink"
              >{{ $t("Auth.emailPasswordResetLink") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ForgotPassword",

  data() {
    return {
      actionColor: this.$settings.appColor,

      form: {
        email: "",
      },

      loading: false,

      rules: {
        email: (v) => /.+@.+\..+/.test(v) || this.$t("Auth.validEmail"),
        required: (v) => !!v || this.$t("fieldRequired"),
      },

      showPassword: false,
    };
  },

  created() {
    if (this.$route.query.email) this.form.email = this.$route.query.email;
  },

  methods: {
    ...mapActions({
      authForgotPassword: "auth/forgotPassword",
      alertError: "app/alertError",
      alertSuccess: "app/alertSuccess",
      alertWarning: "app/alertWarning",
    }),

    error(e) {
      let message = "";

      if (this.$helper.error(e) == "The given data was invalid.") {
        this.$helper.setFieldErrors(e, this);
        this.alertError(this.$t("invalidData"));
      } else {
        message = this.$t("Auth.forgotPasswordError") + this.$helper.error(e);
        this.alertError(message);
      }
    },

    forgotPassword() {
      if (this.validForm()) {
        this.loading = true;
        this.authForgotPassword(this.form)
          .then(() => {
            this.alertSuccess(this.$t("Auth.resetLinkEmailed"));
            this.$router.replace({ name: "home" });
          })
          .catch((e) => this.error(e))
          .finally(() => (this.loading = false));
      }
    },

    validForm() {
      if (this.$refs.form.validate()) return true;

      this.alertWarning(this.$t("invalidData"));
    },
  },
};
</script>
